<template>
    <div>
        <div class="top">
            <div class="topleft">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/home' }">{{$t('home')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>资金流水</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="cont">
            <div class="listbox">
                <div class="listright" v-loading="loading">
                    <div class="search">
                        <div class="searchleft">
                            <el-form :inline="true" :model="searchform" class="demo-form-inline" @submit.native.prevent >
                                <el-form-item label="商户订单号">
                                    <el-input v-model="searchform.mchOrderNo" style="width: 200px;" @keyup.enter.native="onSubmit" size="small"></el-input>
                                </el-form-item>
                                <el-form-item label="交易号">
                                    <el-input v-model="searchform.claveRastreo" style="width: 180px;" @keyup.enter.native="onSubmit" size="small"></el-input>
                                </el-form-item>
                                <el-form-item label="业务类型">
                                    <el-select v-model="searchform.status" placeholder="请选择" style="width: 110px;">
                                        <el-option label="不限" value=""></el-option>
                                        <el-option label="代付成功" value="1"></el-option>
                                        <el-option label="代付失败" value="5"></el-option>
                                        <el-option label="代收成功" value="2"></el-option>
                                        <el-option label="代收拒绝" value="6"></el-option>
                                        <el-option label="提现" value="3"></el-option>
                                        <el-option label="充值" value="4"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="渠道">
                                    <el-select v-model="searchform.channel" placeholder="请选择" style="width: 110px;">
                                        <el-option label="不限" value=""></el-option>
                                        <el-option v-for="item in channellist" :key="item.id" :label="item.name" :value="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="$t('time')">
                                    <el-date-picker
                                    v-model="value1"
                                    type="datetimerange"
                                    size="small"
                                    @change="checkdata"
                                    :picker-options="pickerOptions"
                                    range-separator="至"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    start-placeholder="开始时间"
                                    end-placeholder="结束时间">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item>
                                    <el-button size="small" type="primary" @click="onSubmit">{{$t('query')}}</el-button>
                                    <el-button size="small" type="primary" @click="download">导出</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                    <el-table :data="list"
                        :header-cell-style="{'background':'#f5f5f5','font-weight':'bold','color':'#555555','padding':'6px 0'}" style="width: 100%">
                        <el-table-column prop="createTime" label="入账时间" :width="160">
                            <template slot-scope="scope">
                                {{scope.row.createTime|changedate}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="orgId" label="账户" width="150px"></el-table-column>
                        <el-table-column prop="claveRastreo" label="交易号"></el-table-column>
                        <el-table-column prop="mchOrderNo" label="代付订单号/代收账号"></el-table-column>
                        <!-- <el-table-column prop="accountId" label="应用id"></el-table-column> -->
                        <el-table-column prop="monto1" label="变更前余额" width="120px"></el-table-column>
                        <el-table-column prop="monto2" label="变更余额" width="120px"></el-table-column>
                        <el-table-column prop="monto3" label="变更后余额" width="120px"></el-table-column>
                        <el-table-column prop="monto4" label="订单金额" width="120px"></el-table-column>
                        <el-table-column prop="cost" label="服务费" width="120px"></el-table-column>
                        <el-table-column prop="type" label="业务类型"></el-table-column>
                        <el-table-column prop="channel" label="渠道"></el-table-column>
                    </el-table>
                    <div class="page">
                        <app-page :total="total" :current="pageNo" :pageSize="pageSize"
                            @handleCurrentChange="currentchange" @handleSizeChange="handchange"></app-page>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import apiurl from "@/api/public"
    import _api from "@/api/index"
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import {
        LOAD_CHILDREN_OPTIONS
    } from '@riophae/vue-treeselect'
    export default {
        components: {
            Treeselect
        },
        data() {
            return {
                loading: false,
                list: [],
                searchform:{},
                total: 1,
                pageNo: 1,
                pageSize: 10,
                value1: null,
                channellist: [],
                pickerOptions: {
                    onPick : ({maxDate, minDate}) => {
                        this.selectDate = minDate.getTime()
                        if (maxDate) {
                            this.selectDate = ''
                        }
                    },
                    disabledDate : (time) => {
                        if (this.selectDate !== '') {
                            const one = 60 * 24 * 3600 * 1000
                            const minTime = this.selectDate - one
                            const maxTime = this.selectDate + one
                            return time.getTime() < minTime || time.getTime() > maxTime
                        }
                    }
                }
            }
        },
        created() {
            //this.value1=this.getNowTime()
            this.user=JSON.parse(localStorage.getItem("userinfo"))
            this.getList()
            this.getchannel()
        },
        methods: {
            getchannel() {
                _api.get(apiurl.channellist, {}).then(res => {
                    let newarr = []
                    for(let item of res.data){
                        newarr.push(item)
                    }
                    this.channellist = newarr
                })
            },
            getNowTime() {
                const start = new Date(new Date().getTime() - 3600 * 1000 * 24 * 7)
                    .toISOString()
                    .replace('T', ' ')
                    .split('.')[0]
                const end = new Date(new Date().getTime() - 3600 * 1000 * 24)
                    .toISOString()
                    .replace('T', ' ')
                    .split('.')[0]
                return [start, end]
            },
            checkdata(){
                this.getList()
            },
            download() {
                _api.download(apiurl.cursoDownload, {
                    createTime: this.value1,
                    orderNo: this.searchform.mchOrderNo,
                    claveRastreo: this.searchform.claveRastreo,
                    status: this.searchform.status,
                }).then(res => {
                    let a = document.createElement('a');
                    let blob = new Blob([res], {type: "application/vnd.ms-excel"});
                    let objectUrl = URL.createObjectURL(blob);
                    a.setAttribute("href", objectUrl);
                    a.setAttribute("download", '资金流水数据.xls');
                    a.click();
                })
            },
            getList() {
                this.loading = true
                _api.get(apiurl.curso, {
                    page: this.pageNo,
                    size: this.pageSize,
                    createTime: this.value1,
                    orderNo: this.searchform.mchOrderNo,
                    claveRastreo: this.searchform.claveRastreo,
                    status: this.searchform.status,
                    channel:this.searchform.channel,
                }).then(res => {
                    this.loading = false
                    this.list = res.data.data
                    this.total = res.data.totalCount
                })
            },
            onSubmit() {
                this.pageNo = 1
                this.getList()
            },
            handchange(data) { //分页Size变化
                this.pageSize = data
                this.pageNo = 1
                this.getList()
            },
            currentchange(data) { //当前页变化
                this.pageNo = data
                this.getList()
            },
        }
    }
</script>
<style scoped>
    .lefttop {
        padding: 8px;
    }

    .listbox {
        display: flex;
    }

    .listleft {
        width: 20%;
        border: 1px solid #EBEEF5;
    }

    .listright {
        width: 100%;
        margin-left: 1%;
    }

    .listrighttop {
        padding: 0 10px;
        background: rgb(245, 245, 245);
        height: 40px;
        overflow: hidden;
        line-height: 40px;
        border-bottom: 1px solid #EBEEF5;
    }

    .listrighttop strong {
        float: left;
    }

    .listrighttop span {
        float: right;
    }

    ::v-deep .vue-treeselect__control,
    ::v-deep .vue-treeselect__placeholder,
    ::v-deep .vue-treeselect__single-value {
        height: 30px;
        line-height: 30px;
    }
</style>